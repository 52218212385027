@tailwind base;
@tailwind components;
@tailwind utilities;
 


@layer base {
  :root {
    --background: 50 10% 100%;
    --foreground: 50 71.4% 4.1%;
    --card: 50 97% 95%;
    --card-foreground: 50 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 50 71.4% 4.1%;
    --primary: 50 97% 65%;
    --primary-foreground: 50 20% 18%;
    --secondary: 229 76% 94%;
    --secondary-foreground: 50 39.3% 11%;
    --muted: 50 23% 93%;
    --muted-foreground: 50 8.9% 46.1%;
    --accent: 50 54.3% 92%;
    --accent-foreground: 50 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 50 43% 71%;
    --input: 50 13% 91%;
    --ring: 50 83.3% 57.8%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 50 71.4% 4.1%;
    --foreground: 50 20% 98%;
    --card: 50 21.4% 10.1%;
    --card-foreground: 50 20% 98%;
    --popover: 50 71.4% 4.1%;
    --popover-foreground: 50 20% 98%;
    --primary: 50 97% 65%;
    --primary-foreground: 50 20% 18%;
    --secondary: 229 36% 24%;
    --secondary-foreground: 50 20% 98%;
    --muted: 50 27.9% 16.9%;
    --muted-foreground: 50 10.6% 64.9%;
    --accent: 50 27.9% 16.9%;
    --accent-foreground: 50 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 50 27.9% 16.9%;
    --input: 50 27.9% 16.9%;
    --ring: 50.4 70% 50.4%;
  }
}

 
@layer base {
  * {
		@apply border-border;
	}
	h1, h2, h3, h4, h5, h6 {
		@apply font-bold mb-3;
	}
	h1 {
		@apply text-3xl font-extrabold;
	}
	h2 {
		@apply text-2xl;
	}
	h3 {
		@apply text-xl;
	}
	table {
		@apply w-full h-auto min-w-min;
	}
  body {
    @apply bg-background text-foreground;
  }
  Card {
    @apply bg-card text-card-foreground;
  }
}